<template>
  <AutoComplete
    v-model="inputValue"
    v-bind="$attrs"
    :optionLabel="props.optionLabel"
    :suggestions="objectList?.results"
    dataKey="id"
    dropdown
    forceSelection
    @complete="onSearch"
  />
</template>

<script lang="ts" setup generic="T">
import type {AutoCompleteCompleteEvent} from 'primevue/autocomplete'

const emit = defineEmits<{
  'update:modelValue': [value: T],
}>()
const loading = ref<boolean>(false)
const objectList = ref<Paginated<T>>()
const props = withDefaults(defineProps<{
  optionLabel?: Function | string,
  modelValue: T,
  url: string
}>(), {
  optionLabel: "name"
})
const toast = useToast()

defineOptions({
  inheritAttrs: true
})

const inputValue = computed<any>({
  get() {
    return props.modelValue
  },

  set(value) {
    emit('update:modelValue', value)
  }
})

const onSearch = async(event?: AutoCompleteCompleteEvent) => {
  loading.value = true

  const { $api } = useNuxtApp()
  await $api<Paginated<T>>(props.url, {
    query: {
      name: event?.query
    }
  })
    .then(response => objectList.value = response)
    .catch(error => errorsToToast(error, toast))
    .finally(() => loading.value = true)
}

onSearch()

</script>
